import React, { useEffect } from "react";
import "./Boletim.css";
import { getAluno } from "./GetAluno.js";
import Button from "@mui/material/Button";
import { GlobalStyles, TextField } from "@mui/material";

var util = require("./util.js");



function Boletim() {
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  useEffect(() => {
    document.title = 'Boletim';
  }, []);

  return (
    <>
        <div className="border" id="divBr">
          <TextField
            id="cod"
            label="Código do Aluno"
            variant="outlined"
            onSelect={util.limparnome}
            sx={{ input: { color: 'white' } }}
          />
          {/* <input id="cod" type="text" placeholder="Código Aluno" /> */}
          <TextField
            id="nome"
            label="Nome do Aluno"
            variant="outlined"
            onSelect={util.limparcod}
          />
          {/* <input id="nome" type="text" placeholder="Nome Aluno" /> */}
          <Button
            onClick={() => {
              if (document.getElementById("cod").value) {
                getAluno(document.getElementById("cod").value);
              } else {
                getAluno(document.getElementById("nome").value);
              }
            }}
            variant="outlined"
          >
            Pesquisar
          </Button>
          {/* <button
          onClick={() => {
            if (document.getElementById("cod").value) {
              getAluno(document.getElementById("cod").value);
            } else {
              getAluno(document.getElementById("nome").value);
            }
          }}
        >
          Pesquisar
        </button> */}
          <a className="a"></a>
        </div>
    </>
  );
}

export default Boletim;
