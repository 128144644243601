" codigo pra limpar o nome e depois o codigo";

function limparcod() {
  document.getElementById("cod").value = "";
}

function limparnome() {
  document.getElementById("nome").value = "";
}

module.exports = { limparcod, limparnome };
