import React, { useState } from "react";
import "./Aluno.css";
import { Cadastrar } from "./Cadastrar.js";
import Button from "@mui/material/Button";
function Aluno() {
  // fetch("https://verissimos.ddnsfree.com:8080/lista")
  //   .then((response) => response.json())
  //   .then((data) => {});

  return <Button variant="contained">Hello world</Button>;
}

export default Aluno;
